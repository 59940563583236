<template>
  <tr :style="{'background': props.props.selected ? 'lightyellow' : '', 'color': props.props.selected ? '#000' : ''}">
    <td v-if="config.config.fieldsSelected">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left" >
      {{props.item.grupo_dados_id}}
    </td>
    <td class="justify-left" >
      <span>{{props.item.nome_grupo}}</span>
    </td>
    <td class="justify-center" >
      <v-icon v-text="props.item.icone_tipo"></v-icon>
      <br>
      <span>{{props.item.nome_tipo}}</span>
    </td>
    <td class="justify-left" >
      <div style="width: 100%;">
        <strong :style="{color: props.item.status.cor_status}" v-html="props.item.status.nome_status"></strong>
      </div>
    </td>
    <!-- <td class="justify-center" >
      <v-btn 
      @click="createOrder(props.item)"
      :disabled="props.item.funcao_tipo != 2"
      outline>Abrir Ordem para esta rede</v-btn>
    </td> -->
  </tr>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'NetworkDevices',
    data () {
      return {
        pingDialog: {
          active: false,
          host: null,
          server: null
        }
      }
    },
    methods: {
      createOrder: function (network) {
        console.log(network)
        callApi.get({
          uri: 'service-desk/ticket/' + network.chamado_dados_id,
          params: {},
          msgLoad: true,
          sucess: (response) => {
            console.log(response)
            if (response && response.data && response.data.id) {
              this.$WiEditDialog({
                wiConfig: 'order-master-os',
                data: {
                  person_id: response.data.person_id,
                  person: response.data.person,
                  ticket_id: response.data.id,
                  network_id: network.grupo_dados_id,
                  collaborator_id: response.data.person_responsible_id
                }
              })
            }
          },
          error: (error) => {
            this.$WiMakeError({error: error, id: 103, title: 'Erro ao buscar informações.'})
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>